import {Context} from "@nuxt/types/app";
import {AxiosRequestConfig} from "axios";

export default function (
  {
    app,
    $axios,
    $api,
    $config,
    $sentry
  } : Context
) {
  /** @member {Function[]} requestCallbackQueue
   * очередь запросов, ожидающих токен до того как создастся новый пользователь
   * */
  const requestCallbackQueue: Function[] = []

  /**
   * Проверка на созднаие пользователя (POST /user)
   *
   * @param req - конфигурация запроса axios
   * @returns isPostUserRequest - true = является запросом на создание пользователя
   */
  const isReqCreateUser = (req: AxiosRequestConfig) => req.url && req.url?.includes('user') && req.method && req.method.toLowerCase() === 'post'

  /**
   * Получить токен пользователя
   * Может быть в localStorage или в query[token]
   * @returns {string | null} auth_token - токен авторизованного пользователя
   */
  const getAuthToken = (): string | null => {
    let authToken: null | string = null
    const {token: queryToken} = app.context.query

    try {
      authToken = queryToken || localStorage.getItem('storedToken')
    } catch (_ignore) {
    }

    return authToken
  }

  /**
   * Сохранение токена пользователя
   * Записываем его в localStorage и добавляем заголовок авторизации
   * @params {string} token - токен авторизованного пользователя
   * @returns {boolean}
   */
  const setAuthToken = (token: string): boolean => {
    if (!token) return false
    $axios.setToken(token, 'Bearer')
    $api.setToken(token, 'Bearer')
    try {
      localStorage.setItem('storedToken', token)
    } catch (err) {
      // $sentry.captureException(err)
      console.log(err)
    }
    return true
  }

  /**
   * Проверка авторизации перед исполнением запроса
   * @returns {Promise<void>}
   */
  const authGuard = (): Promise<void> => {
    return new Promise(async (resolve) => {
      // Если токен есть - выставляем его и пропускаем дальше
      let token = getAuthToken()
      if (token) {
        setAuthToken(token)
        resolve()
        return
      }
      // Если токена нет - наполняем очередь запросов
      requestCallbackQueue.push(resolve)
      // На первом запросе - создаем нового пользователя
      if (requestCallbackQueue.length === 1) {
        const res = await $api.post('user', [{}], {
          headers: {
            'X-TOKEN': $config.APP_NEW_USER_TOKEN
          }
        })
        setAuthToken(res.data.auth_key)
        // Как получили пользователя - завершаем все ожидающие методы по очереди
        for (const requestCallback of requestCallbackQueue) {
          requestCallback()
        }
      }
    })
  }

  /**
   * Interceptor запросов с авторизацией
   */
  const authOnRequestHandler = (config) => {
    /** вкл обязательную авторизацию если не определена */
    config.requireToken ??= true
    /** если это POST /user, или авторизация не требуется, или есть заголовок авторизации, то за токеном идти не нужно */
    if (isReqCreateUser(config) || !config.requireToken || (config.headers && config.headers.Authorization)) {
      return config
    }
    return authGuard().then(() => config)
  }

  /**
   * Обработчик ошибок авторизации
   */
  const authOnErrorHandler = (error) => {
    const router = app.router
    const route = app.context.route
    const {response} = error;

    if (response) {
      const code = parseInt(response.status)
      const isAuthRequest = response.request.responseURL.includes('user')
      if (code === 401 && isAuthRequest) {
        if (route.query.auth_key) {
          // todo #1 rm auth_key from query if invalid
          // const {path, query} = route
          // const {auth_token, ...otherParams} = query
          // const newQuery = {...otherParams}
          // const newRoute = {path, query: newQuery}
          // router.replace(newRoute)
        }
        $sentry.configureScope((scope) => {
          scope.setExtra('error', error)
        })
        console.log(error)
        $sentry.captureException(error)
        localStorage.removeItem('storedToken')
        window.location.reload()
      }
    }
  }

  // добавляем функционал для обычного инстанса аксиоса
  $axios.onRequest(authOnRequestHandler)
  $axios.onError(authOnErrorHandler)

  $api.onRequest(authOnRequestHandler)
  $api.onError(authOnErrorHandler)
}
