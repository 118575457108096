import {Context} from '@nuxt/types/app';
import user from '@simple-payments/shared-packages/modules/auth/store/user';
import docs from '@simple-payments/shared-packages/modules/auth/store/docs';
import requisites from '@simple-payments/shared-packages/modules/auth/store/requisites';
import regions from '@simple-payments/shared-packages/modules/auth/store/regions';

/**
 * Подключение модуля user в vuex
 * docs - документы пользователя
 * requisites - уведомления по реквизитам
 * regions - словарик регионов для формы редактирования и поиска по докам фссп
 */
export default function ({ store }: Context) {
    store.registerModule('user', user)
    store.registerModule('docs', docs)
    store.registerModule('requisites', requisites)
    store.registerModule('regions', regions)
}
