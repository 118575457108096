
import { Component, Provide, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import EventBusMixin from '~/mixins/EventBusMixin.vue'
import Features from '~/components/Features.vue'

@Component({
  components: {
    Features,
    LazyHydrate,
    MobileAppBanner: () => import('~/components/notify/banners/MobileAppBanner.vue'),
    Notify: () => import('@simple-payments/authorization/component/Notify.vue'),
    WebPushPopup: () => import('@simple-payments/web-push/lib/components/WebPushPopup.vue')
  },
  mixins: [
    EventBusMixin
  ]
})
export default class extends Vue {
  @Provide('excludeCacheFunction') clearPageCache: (name: string) => void = (name: string) => {
    this.keepAliveExclude.push(name)

    this.$nextTick(() => {
      this.keepAliveExclude.pop()
    })
  }

  keepAliveExclude: string[] = ['payment', 'payment-fail']
  notifyIsOpen: boolean = false
  popupComponent: any = () => import('~/components/Popup.vue')
  preloaderComponent: any = () => import('~/components/Preloader.vue')

  mounted () {
    this.initInAppMessageHandler()
  }

  get showBankText () {
    const { name: routeName } = this.$route
    const routes = ['gibdd-incoming']
    if (routeName) {
      return routes.includes(routeName)
    } else {
      return false
    }
  }

  get showFooter () {
    const { name: routeName } = this.$route
    const hiddenFooterRoutes = ['search-gibdd', 'search-fns', 'payment']
    if (routeName) {
      return !hiddenFooterRoutes.includes(routeName)
    } else {
      return true
    }
  }

  initInAppMessageHandler () {
    if (this.$fireModule.messaging.isSupported()) {
      this.$fire.messaging.onMessage((e) => {
        const isConfirm = confirm(e.notification.body + ' Посмотреть?')

        if (isConfirm) {
          window.location.href = e.notification.click_action
        }
      })
    }
  }
}
